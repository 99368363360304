import * as R from 'ramda/src/index';
import moment from 'moment';
import {toJS} from 'mobx';
import Cookies from 'js-cookie';
import qs from 'qs';
import {
  ferriesDomain, isAirtickets, isAffiliate, language, bookingBaseUrl, currency, localeFull
} from 'helpers/stableValues';
import {
  afterOneYear,
  dateIsSameOrAfterToday,
  obDateIsSameOrAfterTodayAndIbDateIsAfterObDate
} from 'helpers/time';
import generic from 'assets/images/cc-logos/generic.svg';
import {setPartnerCodeHistory} from 'providers/storagePartnerCodeHistory';

export const cacheKey = 'ferry-queries';

export const cacheKeyAccommodation = 'ferry-accomodation';

export const cacheKeyPartnerCodeHistory = 'partner-code-history';

export const sessionItems = {
  tp24Id: 'tp24Id',
  resourceId: 'resourceId',
  segments: 'segments',
  searchUrl: 'searchUrl'
};

export const portCodePattern = /.*\(([^)]+)\).*/;

export const getPortCode = (string = '') => {
  const matchString = string.match(portCodePattern);
  return matchString && matchString[1].toUpperCase();
};

export const male = 'Male';

export const female = 'Female';

export const whole = 'wholeCabin';

export const berth = 'berth';

export const cabin = 'cabin';

export const adult = 'adult';

export const infant = 'infant';

export const passport = 'passport';

export const identity = 'identity';

export const birthDate = 'birthDate';

export const birthPlace = 'birthPlace';

export const nationality = 'nationality';

export const countryOfIssuance = 'countryOfIssuance';

export const invoice = 'invoice';

export const receipt = 'receipt';

export const allCabin = 'allCabin';

export const allSeat = 'allSeat';

export const onlyForMen = 'Only for men';

export const onlyForWomen = 'Only for women';

export const issuePending = 'IssuePending';

export const passportFirstCap = 'Passport';

export const minPassengers = 1;

export const maxPassengers = 9;

export const minVehicles = 0;

export const maxVehicles  = 4;

export const fullAvailability = 9;

export const discountCodeInfant = '1';

export const discountCodeChild = '2';

export const trailerCategoryCode = 'trailer';

export const vehiclesTowed = ['trailer', 'caravan'];

export const exclusiveUse = 'exclusiveUse';

export const shared = 'shared';

export const typesForTotal = ['adult'];

export const portTaxes = 'PORT_TAXES';

export const deliveryMethods = ['Courier', 'OfficeOrPort', 'Office', 'Port', 'ECheckIn', 'BookingConfirmation'];

export const searchStep = '#search';

export const checkoutSteps = ['#seating', '#passenger', '#payment'];

export const confirmationStep = '#confirmation';

export const flexiCode = 10502;

export const stepsUrl = [
  `/${bookingBaseUrl}`,
  `/${bookingBaseUrl}/results`,
  `/${bookingBaseUrl}/seating`,
  `/${bookingBaseUrl}/payment`,
  `/${bookingBaseUrl}/confirmation`
];

export const stepsUrlNativeLayout = [
  `/${bookingBaseUrl}/seating`,
  `/${bookingBaseUrl}/payment`,
  `/${bookingBaseUrl}/confirmation`
];

export const recoverableErrors  = [11000, 11001, 11002, 11003, 11004, 11005, 11006, 11007];

export const responseCodes = [200, 400, 500];

export const normalTicketDiscountCode = '0';

export const membershipCardErrorCodes = ['806', '856', '857', '858', '859', '862', '867', '868', '869', '923'];

export const initPasClassCode = {
  selectedOpt: {
    label: '',
    type: '',
    code: '',
    id: '',
    capacity: 1,
    bookableMinCapacity: 1,
    bookableMaxCapacity: 1,
    availableSeats: 1,
    restrictions: {reservationType: '', exclusions: {discounts: []}},
    gender: [male, female]
  },
  error: [],
  affected: [],
  group: []
};

export const initVehClassCode = {
  label: '', code: '', restrictions: {exclusions: {discounts: []}}
};

export const allSteps = [searchStep, ...checkoutSteps, confirmationStep];

export const isCurrencyEuro = currency.iso_currency === 'EUR';

const typeTranslations = ({adult, child, infant}) => ({adult, child, infant});

const genderTranslations = ({mr, mrs}) => ({Male: mr, Female: mrs});

export const passengerTitle = (type, gender, firstName, lastName, index, translations) => {
  const typeTr = typeTranslations(translations)[type];
  const genderTr = genderTranslations(translations)[gender];
  return firstName || lastName ?
    `${genderTr} ${firstName} ${lastName}` :
    type ? `${index}. ${typeTr}` :
      `${translations.passenger} ${index}`;
};

export const vehicleTitle = (plateNumber, vehicle, i) =>
  plateNumber ? `${vehicle}: ${plateNumber}` : `${vehicle} ${i + 1}`;

export const dateIsValid = (date) => date.reduce((acc, value, index) => {
  const valueIsValid = Boolean(value && value.isValid() && dateIsSameOrAfterToday(value)
    && !value.isAfter(afterOneYear));
  const nextValue = date[index + 1];
  if (nextValue && nextValue.isValid()) {
    return acc && valueIsValid && obDateIsSameOrAfterTodayAndIbDateIsAfterObDate(value, nextValue);
  }
  return acc && valueIsValid;
}, true);

export const capacityMsg = (availability, supported, hasCapacity, hasNotCapacity, hasCapacityNoAvailability) =>
  availability ? hasCapacity : (supported ? hasCapacityNoAvailability : hasNotCapacity);

export const capitalize = R.compose(
  R.join(''),
  R.juxt([R.compose(R.toUpper, R.head), R.compose(R.toLower, R.tail)])
);

export const genderOptions = (maleTr, femaleTr) => (
  [
    {label: maleTr, value: male},
    {label: femaleTr, value: female}
  ]
);

export const defaultPrice = {
  discount: 0,
  faceValue: 0,
  fee: 0,
  tax: 0,
  total: 0,
  totalNoFee: 0,
  meals: 0,
  discountCoupon: 0,
  otherChargesTotal: 0,
  smsNotification: 0,
  donation: 0,
  webCheckinService: 0,
  courier: 0,
  cardType: 0,
  instalment: 0,
  insurance: 0,
  flexiCancellation: 0,
  additionalCosts: [],
  additionalTax: undefined
};

export const defaultCardType = {
  code: -1,
  surcharge: {amount: 0, currency: 'EUR'},
  logo: generic,
  schemes: [],
  generic: false
};

export const noCoverageOptionCode = -1;

export const getPropFromDiscountRestrictions = (segIndex, prop) =>  R.pipe(
  toJS,
  R.pluck('segments'),
  R.reduce((acc, segment) => {
    acc.push(segment[segIndex]);
    return acc;
  }, []),
  R.pluck('passengerType'),
  R.pluck('restrictions'),
  R.pluck(prop)
);

export const lowerCaseAllWordsExceptFirstLetter = (string) =>
  string && string.replace(/\w\S*/g, (word) =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

export const includesLowercase = (a, b) => a?.toLowerCase()?.includes(b?.toLowerCase());

export const startsWithLowercase = (a, b) => a?.toLowerCase()?.startsWith(b?.toLowerCase());

export const lowerCaseFirstLetter = (str) => str.charAt(0).toLowerCase() + str.slice(1);

export const getTooltip = (itinerary, type) =>
  itinerary.reduce((acc, {segments}) =>
    acc + `${segments[0].transport.carriers.notes[type].reduce((accSeg, note) =>
      accSeg + `${note}<br/>`
    ,'')}`
  ,'');

export const checkTooltip = (tooltip) => R.pipe(
  R.filter(Boolean),
  R.isEmpty,
  R.not
)(tooltip.split('<br/>'));

export const getVesselChanges = R.pipe(
  toJS,
  R.pluck('itineraryStops'),
  R.flatten,
  R.filter(({vesselChange}) => vesselChange)
);

export const getVesselChangePoints = R.pipe(
  getVesselChanges,
  R.pluck('departure'),
  R.pluck('name'),
  R.join(', ')
);

export const getVesselChangeVessels = R.pipe(
  getVesselChanges,
  R.pluck('vessel'),
  R.join(', ')
);

const isNonRefundableClass = (isConfirmation) => R.pipe(
  toJS,
  R.pathOr([], isConfirmation ?
    ['classCode', 'attributes'] :
    ['classCode', 'selectedOpt', 'attributes']),
  R.pluck('code'),
  R.includes('non-refundable')
);

export const calcPricePerLegNonRefund = ({passengers, vehicles, additionalCosts, legIndex, isConfirmation = false}) =>
  passengers.reduce((acc, {segments}) => {
    const isNonRefundable = isNonRefundableClass(isConfirmation)(segments[legIndex]);
    return acc + (isNonRefundable ? 0 : segments[legIndex].price.totalNoFee);
  }, 0) +
    vehicles.reduce((acc, {segments}) => acc + segments[legIndex].price.totalNoFee, 0) +
    additionalCosts.reduce((acc, {includeInTotalPrice, price}) => acc + (includeInTotalPrice ? price : 0), 0);

export const paxWithoutInfantsNonRefund = (passengers, legIndex, isConfirmation = false) =>
  passengers.reduce((acc, {segments}) => {
    const discountCode = R.pathOr('', isConfirmation ?
      ['discount', 'code'] :
      ['discount', 'selectedOpt', 'code'], segments[legIndex]);
    const isNonRefundable = isNonRefundableClass(isConfirmation)(segments[legIndex]);
    if (discountCode.toString() !== discountCodeInfant && !isNonRefundable) {
      acc += 1;
    }
    return acc;
  }, 0);

export const getGAparams = () => {
  let gaParam = '';
  const {ga} = window;
  if (ga && ga.loaded) {
    ga(function () {
      const trackers = ga.getAll();
      if (trackers.length) {
        gaParam = trackers[0].get('linkerParam');
      }
    });
  }
  return gaParam;
};

export const domain = ferriesDomain();

export const accomodationUrl = (aid, obDate, ibDate, isRoundTrip, type, value, adults, children = 0, infants = 0) => (
  `https://www.booking.com/searchresults.html?aid=${aid}&checkin=${moment(obDate).format('YYYY-MM-DD')}
  &checkout=${moment(isRoundTrip ? ibDate : moment(obDate).add(3, 'days')).format('YYYY-MM-DD')}
  &dest_type=${type}&dest_id=${value}&radius=10&sb_price_type=total
  &group_adults=${adults}&group_children=${children + infants}`
);

export const partnerCodeHistory = async () => {
  const partnerCode = Cookies.get('partnerCode');
  if (partnerCode) {
    await setPartnerCodeHistory(cacheKeyPartnerCodeHistory, partnerCode);
  }
};

export const createUrlParams = () => {
  const {ref} = qs.parse(location.search.slice(1));
  const params = R.reject(R.isNil, [
    ref ? `ref=${ref}`: undefined
  ]).join('&');

  return params ? `?${params}`: params;
};

export const getNotifications = R.pipe(
  R.pluck('notifications'),
  R.flatten,
  R.filter(({type}) => type === 'genericNote'),
  R.uniqBy(R.prop('description')),
  R.pluck('description'),
  R.reduce((acc, notification) => acc + `${notification}<br/>`, '')
);

export const affiliateHost = `/${language}/${bookingBaseUrl}/${createUrlParams()}`;

export const isFerryPartner = isAirtickets || isAffiliate;

const isEskyPartner = () => {
  const {ref} = qs.parse(location.search.slice(1));
  return ref === 'esky' || (isAffiliate && isAffiliate.affiliationid === 'esky');
};

export const isEsky = isEskyPartner();

export const GR = 'GR';

export const isGreekLocale = localeFull === 'el_GR';

export const locationName = (location, type) => (location ?
  lowerCaseAllWordsExceptFirstLetter(location) : type);

export const FLEXI_CANCELLATION_INSURANCE = 'FlexiCancellationProtectionInsurance';


export const isNative = () => {
  // Check if 'isNativeLayout' is already stored
  const storedLayout = localStorage.getItem('isNativeLayout');
  if (storedLayout === 'true') {
    return 'true';
  }

  // Parse the 'layout' parameter from the current URL
  const layoutType = new URLSearchParams(window.location.search).get('layout');
  const isNativeLayout = layoutType === 'native';

  // Store the result for future use
  localStorage.setItem('isNativeLayout', String(isNativeLayout));

  return isNativeLayout;
};
